.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex: 1;
  height: 100vh;
  overflow: hidden;
  // border: solid 1px green;
}

.scene_wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 100%;
  overflow: hidden;
  // border: solid 1px red;
}

.scene {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: inherit;
  flex: 1;
  justify-content: center;
  // border: solid 1px blue;
}
