@import './variables.scss';
@import 'src/components/styles/components.scss';
@import 'src/scene/Calendar/calendar.scss';

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/fonts/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/roboto-v20-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/roboto-v20-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/roboto-v20-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('/fonts/roboto-v20-latin-500.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/fonts/roboto-v20-latin-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/roboto-v20-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/roboto-v20-latin-500.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/roboto-v20-latin-500.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/roboto-v20-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/roboto-v20-latin-700.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/fonts/roboto-v20-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/roboto-v20-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/roboto-v20-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/roboto-v20-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/roboto-v20-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}

* {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

// fix scroll for firefox
@-moz-document url-prefix() {
  * {
    scrollbar-width: thin;
    scrollbar-color: $scrollbar-color rgba(0, 0, 0, 0.3);
  }
}

::-webkit-scrollbar {
  width: 0.4em;
  height: 0.4em;
}

::-webkit-scrollbar-track {
  background: inherit;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  border: $scrollbar-color;
  border-radius: 8px;
  background-color: $scrollbar-color;
}

::-webkit-scrollbar-corner {
  background: inherit;
}

.fade-transition-enter {
  opacity: 0;
  transform: translateX(-100%);
}
.fade-transition-enter-active {
  opacity: 1;
  transform: translateX(0%);
}
.fade-transition-exit {
  opacity: 1;
  transform: translateX(0%);
}
.fade-transition-exit-active {
  opacity: 0;
  transform: translateX(100%);
}
.fade-transition-enter-active,
.fade-transition-exit-active {
  transition:
    opacity 500ms,
    transform 500ms;
}

input[type='file']::file-selector-button {
  background-color: transparent;
  color: inherit;
  border: 0px;
  border-right: 1px solid #e5e5e5;
  padding: 10px 10px;
  margin-right: 10px;
  transition: 0.5s;
}

input[type='file']::file-selector-button:hover {
  background-color: $blue;
  border: 0px;
  border-right: 1px solid #e5e5e5;
}

input[type='file'] {
  width: 100%;
  padding-right: 10px;
}
