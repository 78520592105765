.calendar_container {
  margin-top: 200px;
}
.rbc-btn {
  color: inherit;
  font: inherit;
  margin: 0;
}

button.rbc-btn {
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled].rbc-btn {
  cursor: not-allowed;
}

button.rbc-input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.rbc-calendar {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
  box-sizing: inherit;
}

.rbc-abs-full,
.rbc-row-bg {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.rbc-ellipsis,
.rbc-event-label,
.rbc-row-segment .rbc-event-content,
.rbc-show-more {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rbc-rtl {
  direction: rtl;
}

.rbc-off-range {
  color: #999999;
}

.rbc-off-range-bg {
  transition: all 0.2s linear;
  background: #e6e6e6;
}

.dark {
  .rbc-off-range-bg {
    transition: all 0.2s linear;
    background: $darkestblue;
  }
}

.rbc-header {
  overflow: hidden;
  flex: 1 0 0%;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 90%;
  min-height: 0;
  border-bottom: 1px solid black;
}
.rbc-header + .rbc-header {
  border-left: 1px solid black;
}
.rbc-rtl .rbc-header + .rbc-header {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}

.rbc-btn-group2 {
  margin: 0;
  width: 310px;
  & > button {
    width: 50%;
  }
}

.dark {
  .rbc-header {
    overflow: hidden;
    flex: 1 0 0%;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 3px;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    font-size: 90%;
    min-height: 0;
    border-bottom: 1px solid $gray;
  }
  .rbc-header + .rbc-header {
    border-left: 1px solid $gray;
  }
  .rbc-rtl .rbc-header + .rbc-header {
    border-left-width: 0;
    border-right: 1px solid $gray;
  }
}
.rbc-header > a,
.rbc-header > a:active,
.rbc-header > a:visited {
  color: inherit;
  text-decoration: none;
}

.rbc-row-content {
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  z-index: 4;
}

.rbc-spans {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rbc-row-content-scrollable {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.rbc-row-content-scrollable .rbc-row-content-scroll-container {
  height: 100%;
  overflow-y: scroll;
  /* Hide scrollbar for Chrome, Safari and Opera */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.rbc-row-content-scrollable .rbc-row-content-scroll-container::-webkit-scrollbar {
  display: none;
}

.rbc-today {
  transition: all 0.2s linear;
  background-color: #eaf6ff;
}

.dark {
  .rbc-today {
    transition: all 0.2s linear;
    background-color: #203147;
  }
}

.rbc-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  font-size: 16px;
}
.rbc-toolbar .rbc-toolbar-label {
  // flex-grow: 1;
  padding: 0 10px;
  text-align: center;
}
.rbc-toolbar button {
  display: inline-block;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  background-image: none;
  border: 1px solid #ccc;
  padding: 0.375rem 1rem;
  border-radius: 4px;
  line-height: normal;
  white-space: nowrap;
}
.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  background-image: none;
  background-color: $blue;
  border-color: #adadad;
}
.rbc-toolbar button:active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button.rbc-active:focus {
  color: #373a3c;
  background-color: $blue-light;
  border-color: #8c8c8c;
}
// .rbc-toolbar button:focus {
//   color: #373a3c;
//   background-color: $blue;
//   border-color: #adadad;
// }
.rbc-toolbar button:hover {
  color: #373a3c;
  background-color: $blue-light;
  border-color: #adadad;
}

.rbc-btn-group {
  display: inline-block;
  white-space: nowrap;

  button {
    min-width: 104px;
    margin-top: 1px;
  }
}
.rbc-btn-group > button:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-btn-group > button:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-rtl .rbc-btn-group > button:first-child:not(:last-child) {
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-rtl .rbc-btn-group > button:last-child:not(:first-child) {
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rbc-btn-group > button:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.rbc-btn-group button + button {
  margin-left: -1px;
}
.rbc-rtl .rbc-btn-group button + button {
  margin-left: 0;
  margin-right: -1px;
}
.rbc-btn-group + .rbc-btn-group,
.rbc-btn-group + button {
  margin-left: 10px;
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  padding: 0px 5px;
  background-color: #3174ad;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  text-align: left;
}
.rbc-slot-selecting .rbc-event,
.rbc-slot-selecting .rbc-day-slot .rbc-background-event,
.rbc-day-slot .rbc-slot-selecting .rbc-background-event {
  cursor: inherit;
  pointer-events: none;
}
.rbc-event.rbc-selected,
.rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: #265985;
}
.rbc-event:focus,
.rbc-day-slot .rbc-background-event:focus {
  outline: 5px auto #3b99fc;
}

.rbc-event-label {
  font-size: 80%;
}

.rbc-event-overlaps {
  box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
}

.rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rbc-event-continues-later {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.rbc-row {
  display: flex;
  flex-direction: row;
}

.rbc-row-segment {
  padding: 0 1px 1px 1px;
}

.rbc-selected-cell {
  background-color: rgba(0, 0, 0, 0.1);
  transition: all 0.2s linear;
}

.dark {
  .rbc-selected-cell {
    transition: all 0.2s linear;
    background-color: $blue !important;
  }
}

.rbc-show-more {
  z-index: 4;
  font-weight: bold;
  font-size: 85%;
  height: auto;
  line-height: normal;
  color: $blue;
  transition: all 0.2s linear;
}

.dark {
  .rbc-show-more {
    transition: all 0.2s linear;
    color: $blue-light;
  }
}

.rbc-month-view {
  position: relative;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  height: 100%;
}

.dark {
  .rbc-month-view {
    position: relative;
    border: 1px solid $gray;
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    width: 100%;
    user-select: none;
    -webkit-user-select: none;
    height: 100%;
  }
}

.rbc-month-header {
  display: flex;
  flex-direction: row;
}

.rbc-month-row {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 0 0;
  flex-basis: 0px;
  overflow: hidden;
  height: 100%;
}
.rbc-month-row + .rbc-month-row {
  border-top: 1px solid black;
}

.dark {
  .rbc-month-row + .rbc-month-row {
    border-top: 1px solid $gray;
  }
}

.rbc-date-cell {
  flex: 1 1 0;
  min-width: 0;
  padding-right: 5px;
  text-align: right;
}
.rbc-date-cell.rbc-now {
  font-weight: bold;
}
.rbc-date-cell > a,
.rbc-date-cell > a:active,
.rbc-date-cell > a:visited {
  color: inherit;
  text-decoration: none;
}

.rbc-row-bg {
  display: flex;
  flex-direction: row;
  flex: 1 0 0;
  overflow: hidden;
}

.rbc-day-bg {
  flex: 1 0 0%;
}
.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid black;
}
.rbc-rtl .rbc-day-bg + .rbc-day-bg {
  border-left-width: 0;
  border-right: 1px solid black;
}

.dark {
  .rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid $gray;
  }
  .rbc-rtl .rbc-day-bg + .rbc-day-bg {
    border-left-width: 0;
    border-right: 1px solid $gray;
  }
}

.rbc-overlay {
  position: absolute;
  z-index: 5;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  padding: 10px;
  overflow: auto;
  max-height: 300px;
}
.rbc-overlay > * + * {
  margin-top: 1px;
}

.rbc-overlay::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.rbc-overlay::-webkit-scrollbar-track {
  background: inherit;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.rbc-overlay::-webkit-scrollbar-thumb {
  background-color: $blue;
  border-radius: 8px;
  border: $blue;
}

.rbc-overlay-header {
  border-bottom: 1px solid #e5e5e5;
  margin: -10px -10px 5px -10px;
  padding: 2px 10px;
}

.rbc-agenda-view {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  overflow: auto;
  margin: 10px 5px;
}
.rbc-agenda-view table.rbc-agenda-table {
  width: 100%;
  border: 1px solid black;
  border-spacing: 0;
  border-collapse: collapse;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
  padding: 5px 10px;
  vertical-align: top;
}
.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
  padding-left: 15px;
  padding-right: 15px;
  text-transform: lowercase;
  border-left: 1px solid black;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left: 1px solid black;
}
.rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left-width: 0;
  border-right: 1px solid black;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
  border-top: 1px solid black;
}
.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  padding: 3px 5px;
  text-align: left;
  border-bottom: 1px solid black;
}
.rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  text-align: right;
}

.dark {
  border-radius: 10px;

  .rbc-agenda-view table.rbc-agenda-table {
    width: 100%;
    border: 1px solid $gray;
    border-spacing: 0;
    border-collapse: collapse;
  }
  .rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
    padding-left: 15px;
    padding-right: 15px;
    text-transform: lowercase;
    border-left: 1px solid $gray;
  }
  .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
    border-left: 1px solid $gray;
  }
  .rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
    border-left-width: 0;
    border-right: 1px solid $gray;
  }
  .rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
    border-top: 1px solid $gray;
  }
  .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
    padding: 3px 5px;
    text-align: left;
    border-bottom: 1px solid $gray;
  }
}

.rbc-agenda-time-cell {
  text-transform: lowercase;
}
.rbc-agenda-time-cell .rbc-continues-after:after {
  content: ' »';
}
.rbc-agenda-time-cell .rbc-continues-prior:before {
  content: '« ';
}

.rbc-agenda-date-cell,
.rbc-agenda-time-cell {
  white-space: nowrap;
}

.rbc-agenda-event-cell {
  width: 100%;
}

.rbc-time-column {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.rbc-time-column .rbc-timeslot-group {
  flex: 1;
}

.rbc-timeslot-group {
  min-height: 100px;

  display: flex;
  flex-flow: column nowrap;
  border-bottom: 1px solid black;
}

.dark {
  .rbc-timeslot-group {
    border-bottom: 1px solid $gray;
    display: flex;
    flex-flow: column nowrap;
    min-height: 100px;
  }
}

.rbc-time-gutter,
.rbc-header-gutter {
  flex: none;
}

.rbc-label {
  padding: 0 5px;
}

.dark {
}

.rbc-day-slot {
  position: relative;
}
.rbc-day-slot .rbc-events-container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  margin-right: 10px;
  top: 0;
}
.rbc-events-container {
  width: 100%;
}
.rbc-day-slot .rbc-events-container.rbc-rtl {
  left: 10px;
  right: 0;
}
.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
  border: 1px solid #265985;
  display: flex;
  max-height: 100%;
  min-height: 14px;
  flex-flow: column wrap;
  align-items: flex-start;
  overflow: hidden;
  position: absolute;
  justify-content: center;
}
.rbc-day-slot .rbc-background-event {
  opacity: 0.75;
}
.rbc-day-slot .rbc-event-label {
  flex: none;
  padding-right: 5px;
  width: auto;
}
.rbc-day-slot .rbc-event-content {
  width: 100%;
  flex: 1 1 0;
  word-wrap: break-word;
  line-height: 1;
  height: 100%;
  min-height: 1em;
}
.rbc-day-slot .rbc-time-slot {
  border-top: 1px solid black;
}

.rbc-time-view-resources .rbc-time-gutter,
.rbc-time-view-resources .rbc-time-header-gutter {
  position: sticky;
  left: 0;
  background-color: white;
  border-right: 1px solid black;
  z-index: 10;
  margin-right: -1px;
}

.dark {
  .rbc-day-slot .rbc-time-slot {
    border-top: 1px solid $gray;
  }

  .rbc-time-view-resources .rbc-time-gutter,
  .rbc-time-view-resources .rbc-time-header-gutter {
    position: sticky;
    left: 0;
    background-color: white;
    border-right: 1px solid $gray;
    z-index: 10;
    margin-right: -1px;
  }
}

.rbc-time-view-resources .rbc-time-header {
  overflow: hidden;
}

.rbc-time-view-resources .rbc-time-header-content {
  min-width: auto;
  flex: 1 0 0;
  flex-basis: 0px;
}

.rbc-time-view-resources .rbc-time-header-cell-single-day {
  display: none;
}

.rbc-time-view-resources .rbc-day-slot {
  min-width: 140px;
}

.rbc-time-view-resources .rbc-header,
.rbc-time-view-resources .rbc-day-bg {
  width: 140px;
  flex: 1 1 0;
  flex-basis: 0 px;
}

.rbc-time-header-content + .rbc-time-header-content {
  margin-left: -1px;
}

.rbc-time-slot {
  flex: 1 0 0;
}
.rbc-time-slot.rbc-now {
  font-weight: bold;
}

.rbc-day-header {
  text-align: center;
}

.rbc-slot-selection {
  z-index: 10;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 75%;
  width: 100%;
  padding: 3px;
}

.rbc-slot-selecting {
  cursor: move;
}

.rbc-time-view {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  border: 1px solid black;
  min-height: 0;
}

.dark {
  .rbc-time-view {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    border: 1px solid $gray;
    min-height: 0;
  }
}
.rbc-time-view .rbc-time-gutter {
  white-space: nowrap;
}
.rbc-time-view .rbc-allday-cell {
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  position: relative;
}
.rbc-time-view .rbc-allday-cell + .rbc-allday-cell {
  border-left: 1px solid #ddd;
}
.rbc-time-view .rbc-allday-events {
  position: relative;
  z-index: 4;
}
.rbc-time-view .rbc-row {
  box-sizing: border-box;
  min-height: 20px;
}

.rbc-time-header {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
}
.rbc-time-header.rbc-overflowing {
  margin-right: 0px !important;
}
.rbc-rtl .rbc-time-header.rbc-overflowing {
  border-right-width: 0;
  border-left: 1px solid #ddd;
}
.rbc-time-header > .rbc-row:first-child {
  border-bottom: 1px solid #ddd;
}
.rbc-time-header > .rbc-row.rbc-row-resource {
  border-bottom: 1px solid #ddd;
}

.rbc-time-header-cell-single-day {
  display: none;
}

.rbc-time-header-content {
  flex: 1;
  display: flex;
  min-width: 0;
  flex-direction: column;
  border-left: 1px solid black;
}

.dark {
  .rbc-time-header-content {
    flex: 1;
    display: flex;
    min-width: 0;
    flex-direction: column;
    border-left: 1px solid $gray;
  }
}

.rbc-rtl .rbc-time-header-content {
  border-left-width: 0;
  border-right: 1px solid #373a3c;
}
.rbc-time-header-content > .rbc-row.rbc-row-resource {
  border-bottom: 1px solid #ddd;
  flex-shrink: 0;
}

.rbc-time-content {
  display: flex;
  flex: 1 0 0%;
  align-items: flex-start;
  width: 100%;
  border-top: 1px solid black;
  position: relative;
}

.dark {
  .rbc-time-content {
    display: flex;
    flex: 1 0 0%;
    align-items: flex-start;
    width: 100%;
    border-top: 1px solid $gray;
    position: relative;
  }
}

.rbc-time-content::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

.rbc-time-content::-webkit-scrollbar-track {
  background: inherit;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.rbc-time-content::-webkit-scrollbar-thumb {
  background-color: $blue;
  border-radius: 8px;
  border: $blue;
}

.rbc-time-content::-webkit-scrollbar-corner {
  background: inherit;
}

.rbc-time-content > .rbc-time-gutter {
  flex: none;
}
.rbc-time-content > * + * > * {
  border-left: 1px solid black;
}

.dark {
  .rbc-time-content {
    display: flex;
    flex: 1 0 0%;
    align-items: flex-start;
    width: 100%;
    border-top: 1px solid $gray;
    overflow-y: auto;
    position: relative;
  }
  .rbc-time-content > .rbc-time-gutter {
    flex: none;
  }
  .rbc-time-content > * + * > * {
    border-left: 1px solid $gray;
  }
}

.rbc-rtl .rbc-time-content > * + * > * {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}
.rbc-time-content > .rbc-day-slot {
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
}

.rbc-current-time-indicator {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #74ad31;
  pointer-events: none;
}

.rbc-agenda-view::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.rbc-agenda-view::-webkit-scrollbar-track {
  background: inherit;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.rbc-agenda-view::-webkit-scrollbar-thumb {
  background-color: $blue;
  border-radius: 8px;
  border: $blue;
}

.rbc-agenda-view::-webkit-scrollbar-corner {
  background: inherit;
}

.rbc-agenda-event-cell {
  transition: 0.2s;
}
.rbc-agenda-event-cell:hover {
  cursor: pointer;
  background: #8ed1f3;
  color: black;
}

.picker {
  // min-width: 550px;
  height: 280px;

  &__upBlock {
    padding: 10px 10px;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    height: 100px;
  }
  &__item {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: inherit;
    justify-content: flex-start;
    width: 150px;
    // &_device {
    //   text-align: center;
    // }
  }
  &__item_big {
    overflow: auto;
    align-self: center;
  }
  &__title {
    font-weight: 500;
    text-align: center;
    width: 100%;
    margin-bottom: 15px;
    align-self: center;
  }
  // &__title_device {
  //   text-align: center;
  // }
  // &__item_device {
  //   align-items: center;
  // }

  &__downTitle {
    width: 100%;
    margin: 0;
  }
}

.react-datepicker-popper {
  z-index: 100 !important;
}

.input_error {
  bottom: 10px;
  color: red;
}
.react-datepicker__input-container {
  cursor: pointer;
}

.react-datepicker__input-container > input {
  border: none !important;
  font-size: 12px;
  // border-bottom: 1px solid #c4c4c4 !important;
  background: transparent;
  cursor: pointer;
}

.picker__item_device {
  align-items: center;
}
