$blue: #00a1e4;
$blue-light: #8ed1f3;

$gray: #6c7883;
$darkestblue: #16283c;

$error: #d32f2f;

$scrollbar-color: #297acc;

$white: #ffffff;
$black: #000000;
$light-container: #f8f9fd;
$dark-container: #181818;
$dark-form: #121212;
